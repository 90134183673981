@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url("/fonts/proxima-nova/ProximaNova-Regular.woff2") format("woff2"),
    url("/fonts/proxima-nova/ProximaNova-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url("/fonts/proxima-nova/ProximaNova-Bold.woff2") format("woff2"),
    url("/fonts/proxima-nova/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url("/fonts/proxima-nova/ProximaNova-Medium.woff2") format("woff2"),
    url("/fonts/proxima-nova/ProximaNova-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url("/fonts/proxima-nova/ProximaNova-MediumIt.woff2") format("woff2"),
    url("/fonts/proxima-nova/ProximaNova-MediumIt.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url("/fonts/proxima-nova/ProximaNova-Black.woff2") format("woff2"),
    url("/fonts/proxima-nova/ProximaNova-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url("/fonts/proxima-nova/ProximaNova-Light.woff2") format("woff2"),
    url("/fonts/proxima-nova/ProximaNova-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url("/fonts/proxima-nova/ProximaNova-Extrabld.woff2") format("woff2"),
    url("/fonts/proxima-nova/ProximaNova-Extrabld.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
}
